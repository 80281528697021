<script setup>
import CreateWorkspaceModal from '~/components/modals/CreateWorkspaceModal.vue';

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const workspaceStore = useWorkspaceStore();
const { workspace, workspaces } = storeToRefs(workspaceStore);

const props = defineProps({
  navigate: {
    type: Boolean,
    default: false,
  },
});

const modal = useModal();
const assumedId = ref('');

const openCreateWorkspaceModal = () => {
  modal.open(CreateWorkspaceModal);
};

const workspaceSelectItems = computed(() => {
  const baseItems = [
    workspaces.value.map((workspace) => ({
      label: workspace.name,
      icon: 'i-ph-storefront',
      click: () => workspaceStore.changeWorkspace(workspace.id, props.navigate),
    })),
    [
      {
        label: 'New workspace',
        icon: 'i-ph-note-pencil',
        click: openCreateWorkspaceModal,
      },
    ],
  ];

  if (user.value?.isAdmin) {
    baseItems.push([
      {
        slot: 'assume',
      },
    ]);
  }

  return baseItems;
});
</script>
<template>
  <UDropdown
    class="w-full pt-m"
    :items="workspaceSelectItems"
    :popper="{ placement: 'bottom-start' }"
    :ui="{
      item: {
        base: 'gap-x-4 w-full',
        inactive: 'text-gray-500 hover:text-gray-900',
        size: 'text-lg',
        icon: {
          base: 'flex-shrink-0 w-6 h-6',
        },
      },
      width: 'w-64',
    }"
  >
    <UButton
      color="white"
      :padded="true"
      size="xl"
      icon="i-ph-storefront"
      class="w-full"
      :ui="{
        padding: { xl: 'px-2.5' },
        gap: { xl: 'gap-x-4' },
        size: { xl: 'text-nowrap text-lg' },
        color: {
          white: { solid: 'text-gray-500 hover:text-gray-900 font-normal' },
        },
      }"
    >
      <span class="overflow-hidden text-ellipsis whitespace-nowrap">
        {{ workspace.name }}
      </span>
      <template #trailing>
        <UIcon name="i-ph-caret-down" class="ml-auto w-5 h-5" />
      </template>
    </UButton>
    <template #assume="{ item }">
      <UButtonGroup
        size="lg"
        class="w-full"
        orientation="horizontal"
        @click.stop
      >
        <UInput
          v-model="assumedId"
          placeholder="Assume workspace with ID"
          class="w-full"
        />
        <UButton
          @click="() => workspaceStore.assumeWorkspace(assumedId)"
          icon="i-ph-detective-fill"
          color="gray"
        />
      </UButtonGroup>
    </template>
  </UDropdown>
</template>
